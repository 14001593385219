<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Perfil</h2>
      </div>
    </div>
    <div class="section-content">
    <div class="list-container">
      <b-card class="card-text shadow-sm bg-white rounded">
        <b-card-title class="card-title">
          Dados gerais
        </b-card-title>
        <b-row>
          <b-col cols="2" style="padding-left: 50px; padding-top: 25px">
            <b-card-text class="card-style" v-text="general.name">
            </b-card-text>
          </b-col>
          <b-col style="padding-top: 25px">
            <b-card-text v-text="userName">
            </b-card-text>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2" style="padding-left: 50px; padding-top: 15px">
            <b-card-text class="card-style" v-text="general.email">
            </b-card-text>
          </b-col>
          <b-col style="padding-top: 15px;">
            <b-card-text v-text="login">
            </b-card-text>
          </b-col>
            <b-col v-show="!canShowCorporate" cols="2">
              <b-card-text v-show="!canShowCorporate">
                <strong v-if="!canShowCorporate">Corporação </strong>
              </b-card-text>
            </b-col>
            <b-col v-if="!canShowCorporate">
              <b-card-text v-text="corporateName">
              </b-card-text>
            </b-col>
        </b-row >
        <b-button v-if="canShowCorporate" class="corporate-button" @click="onCreateCorporate()">
          Criar conta corporativa </b-button>
        <b-button v-if="!canShowCorporate" class="corporate-button" @click="onEditCorporate()">
          Editar conta </b-button>
      </b-card>

      <b-card class="card-text shadow-sm bg-white rounded">
        <b-card-title class="card-title">
          Alteração de senha
        </b-card-title>
        <b-row style="max-width: 500px;">
          <b-col> <PasswordVerificationForm v-if="email" :showTextVersion="false" :userLogin="email" :redirectLoginPage="false"></PasswordVerificationForm></b-col>
        </b-row>
      </b-card>
      <b-card class="card-text shadow-sm bg-white rounded">
        <b-card-title class="card-title">
          API Tokens
        </b-card-title>
        <div>
          <b-table :fields="tokenFields" :items="filteredTokens">
            <template v-slot:cell(detail)="row">
              <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                {{ row.detailsShowing ? 'Esconder' : 'Mostrar'}}
              </b-button>
            </template>
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Token:</b></b-col>
                  <b-col>{{ row.item.key }}</b-col>
                </b-row>
              </b-card>
            </template>
            <template v-slot:cell(key)="row">
              <span v-b-tooltip.hover :title=tooltipCopyToken>
                <img class="item-token-table" :src="copyTokenIcon" @click="copyToken(row.item, row.index, $event.target)">
              </span>
            </template>
            <template v-slot:cell(remove)="row">
              <span v-b-tooltip.hover :title=tooltipRemoveToken>
                <img class="item-token-table" :src="deleteTokenIcon" @click="deleteToken(row.item, row.index, $event.target)">
              </span>
            </template>
          </b-table>
          <b-button class="corporate-button" @click="onCreateTokenClick()">
            Criar token </b-button>
            <b-link @click="onHowToClick()" class="howTo" >Como usar</b-link>
        </div>
      </b-card>
      <b-card class="card-text shadow-sm bg-white rounded">
          <img class="item-table" :src="deleteIcon" @click="onRemoveAccount()">
          <b-link class="delete-link" @click="onRemoveAccount()">Remover minha conta</b-link>
      </b-card>
    </div>
      <b-modal
        id="modal-prevent-closing"
        ref="modalToken"
        :ok-title="okTitle"
        cancel-title="Cancelar"
        cancel-variant="transparent"
        :title="modalTokenTitle"
        @hidden="resetTokenModal"
        @ok="handleModalTokenOk"
        size="lg"
      >
        <b-form @submit.stop.prevent="handleTokenSubmit">
          <b-row>
            <b-col>
              <b-form-group label="Nome" label-for="name-input" style="font-weight: 600" :state="nameTokenState" invalid-feedback="Nome é necesspario">
                <b-form-input id="name-input" :state="nameTokenState" v-model="token.name" placeholder="Digite o nome..." class="border border-dark" autofocus></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
              id="modal-prevent-closing"
              ref="modal"
              :ok-title="okTitle"
              cancel-title="Cancelar"
              cancel-variant="transparent"
              :title="modalTitle"
              @hidden="resetModal"
              @ok="handleModalOk"
              size="lg"
      >
        <b-form @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <b-card no-body :disabled="disableAdminChoose">
                <b-card-header v-bind:style="headerAllChose"> Remover conta</b-card-header>
                <b-card-body>
                  <b-card-text>Deseja remover a conta e todos os seus usuários ?</b-card-text>
                  <b-form-checkbox switch v-model="removeAllCheckBox" @change="allAccount()">Excluir a conta e seus usuários</b-form-checkbox>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col style="max-width: 10px; text-align: center; align-items: center; justify-content: center; align-self: center">
              <p>OU</p>
            </b-col>
            <b-col style="margin-left: 10px;">
              <b-card :disabled="disableAllChoose" no-body ref="cardAdminChoose">
                <b-card-header v-bind:style="headerAdminChoose">Selecionar Admin</b-card-header>
                <b-table :fields="usersField" :items="filteredUsersGroups" sticky-header class="user-table" style="{ max-height: 300px; height: 300px; }" thead-class="d-none">
                  <template v-slot:cell(action)="row">
                    <b-form-radio :disabled="radioGroupDisabled" switch size="md" v-model="userAdminSelected" name="check-button" @change="userAdmin(row.item, row.index, $event)">
                    </b-form-radio>
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal class="body" ref="howToModal" cancel-variant="transparent" cancel-title="Cancelar">
        <h4 class="ml-2">Requests para API</h4>
        <b-form-group class="tab">
          <ol type="1">
            <li><p class="aboutAccuracy mt-4">Ao solicitar uma nova transcrição de áudio</p>
          <p> <code> POST https://services.retextoar.com.br/transcription</code></p>

  <pre>
    body:
  <code>
    {
      multichannel: boolean,
      name: string
    }
  </code>
    header:
    <code>
    {
      Cookie: access_token: token
    }
    </code>
  </pre>
              <p>Se o envio for correto irá retornar um <b>'id'</b> para utilizar posteriormente</p>
            </li>
            <li>
          <p class="aboutAccuracy mt-4">Para resgatar a transcrição, será utilizado o <b>'id'</b> recebido</p>
          <p> <code> GET https://services.retextoar.com.br/transcription/:id</code></p>
          <p>Sendo o retorno o json com o conteúdo do áudio</p>
            </li>
          </ol>
        </b-form-group>
      </b-modal>
    </div>
  </section>
</template>

<script>
  import { userService } from '../services/user_service';
  import PasswordVerificationForm from './PasswordVerificationForm.vue';
  import deleteIcon from '@/assets/icons/delete_red.svg';
  import deleteTokenIcon from '@/assets/icons/delete.svg';
  import copyTokenIcon from '@/assets/icons/copy.svg';
  import moment from 'moment';

  export default {
    components: {
      PasswordVerificationForm
    },
    name: 'Profile',
    data () {
      return {
        okTitle: 'Confirmar',
        modalTitle: 'Remover conta/Selecionar Admin',
        modalTokenTitle: 'Criar novo token',
        tooltipCopyToken: 'Copiar',
        tooltipRemoveToken: 'Remover',
        general: {
          name: 'Nome',
          email: 'E-mail'
        },
        usersField: [
          { key: 'action', label: '', sortable: false, thStyle: { width: '50px' } },
          { key: 'name', label: '', sortable: false, sortDirection: 'desc' }
        ],
        tokens: [],
        token: {
          name: '',
          date: '',
          key: ''
        },
        tokenFields: [
          { key: 'name', label: 'Nome', sortable: false },
          {
            key: 'date',
            label: 'Data',
            sortable: false,
            formatter: (value, key, item) => {
              return moment.unix(value / 1000).format('DD/MM/YYYY HH:mm');
            }
          },
          { key: 'detail', label: '', sortable: false, thStyle: { width: '140px' } },
          { key: 'key', label: '', sortable: false, thStyle: { width: '100px' } },
          { key: 'remove', label: '', sortable: false, thStyle: { width: '100px' } }
        ],
        deleteIcon,
        deleteTokenIcon,
        copyTokenIcon,
        userInfo: undefined,
        corporateName: '',
        login: '',
        userName: '',
        removeAllCheckBox: false,
        nameTokenState: null,
        passState: null,
        passConfirmState: null,
        newPassValue: '',
        confirmationNewPass: '',
        canShowCorporate: false,
        userAdminSelected: '',
        email: null,
        disableAdminChoose: false,
        disableAllChoose: false,
        radioGroupDisabled: false,
        headerAllChose: {},
        headerAdminChoose: {}
      };
    },
    created () {},
    async mounted () {
      this.userInfo = await userService.userInfo();
      if (this.userInfo) {
        const { organization } = this.userInfo;
        if (organization) {
          this.corporateName = this.userInfo.organization.name;
        } else {
          this.canShowCorporate = true;
        }
        this.login = this.userInfo.email;
        this.userName = this.userInfo.name;
        this.disableAdminChoose = false;
        this.disableAllChoose = false;
        this.radioGroupDisabled = false;
      }
      this.tokens = await userService.tokens();
    },
    beforeUpdate () {
      this.email = this.login;
    },
    methods: {
      onHowToClick () {
        this.$refs.howToModal.show();
      },
      allAccount () {
        this.disableAdminChoose = true;
        this.disableAllChoose = false;
        this.userAdminSelected = '';
        if (this.removeAllCheckBox) {
          this.headerAllChose = { background: '#6c9aba' };
          this.headerAdminChoose = { background: 'rgb(0, 0, 0, 0.03)' };
          this.radioGroupDisabled = true;
        } else {
          this.headerAllChose = { background: 'rgb(0, 0, 0, 0.03)' };
          this.radioGroupDisabled = false;
        }
      },
      userAdmin (item, index, check) {
        this.disableAdminChoose = false;
        this.disableAllChoose = true;
        this.userAdminSelected = item;
        if (!this.removeAllCheckBox) {
          this.headerAdminChoose = { background: '#6c9aba' };
        } else {
          this.headerAdminChoose = { background: 'rgb(0, 0, 0, 0.03)' };
        }

        this.radioGroupDisabled = false;
      },
      resetModal () {
      },
      resetTokenModal () {
      },
      handleModalTokenOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        this.handleTokenSubmit();
      },
      async handleTokenSubmit () {
        this.nameTokenState = this.token.name.length > 3;
        if (this.nameTokenState) {
          await userService.tokenRegister({ name: this.token.name });
          this.$toast.open('Token Registrado com sucesso!');
          this.$refs.modalToken.hide();
          this.tokens = await userService.tokens();
        }
      },
      handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        this.handleSubmit();
      },
      async handleSubmit (e) {
        if (this.disableAllChoose) {
          await userService.updateAdmin({ login: this.userAdminSelected.name });
          this.$toast.open('Conta alterada com sucesso!');
          await this.removeUser(false);
        } else {
          this.$bvModal.msgBoxConfirm(`Tem certeza que deseja remover ${this.login} ?`, {
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            okTitle: 'Excluir',
            cancelTitle: 'Cancelar',
            footerClass: 'p-2',
            headerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(async (value) => { if (value) { await this.removeUser(true); } }).catch((err) => {
            console.log(err);
          });
        }
      },
      async removeUser (removeAll) {
        const res = await userService.remove(this.login, removeAll);
        if (res) {
          this.$toast.open('Conta excluída com sucesso!');
          setTimeout(() => {
            this.$router.push({ path: 'login' });
          }, 2500);
        }
      },
      async onRemoveAccount () {
        if (this.$store.state.account.isAdmin && this.userInfo.organization.users.length > 1) {
          this.$refs.modal.show();
        } else {
          this.$bvModal.msgBoxConfirm(`Tem certeza que deseja remover ${this.login} ?`, {
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            okTitle: 'Excluir',
            cancelTitle: 'Cancelar',
            footerClass: 'p-2',
            headerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(async (value) => { if (value) { await this.removeUser(true); } }).catch((err) => {
            console.log(err);
          });
        }
      },
      onCreateCorporate () {
        this.$router.push({ path: 'corporateRegister' });
      },
      onEditCorporate () {
        this.$router.push({ name: 'CorporateRegister', params: { organization: this.userInfo.organization } });
      },
      deleteToken (item, index, button) {
        this.$bvModal.msgBoxConfirm(`Tem certeza que deseja remover ${item.name} ?`, {
          title: 'Remover token',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async value => {
            if (value) {
              await userService.tokenRemove(item.key);
              this.$toast.open('Token excluído com sucesso!');
              this.tokens = await userService.tokens();
            }
          })
          .catch(err => {
            console.log(err);
          });
        console.log('delete token');
      },
      async copyToken (item, index, button) {
        try {
          const successful = await navigator.clipboard.writeText(item.key);
          const msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }
      },
      onCreateTokenClick () {
        this.$refs.modalToken.show();
      }
    },
    computed: {
      filteredTokens: function () {
        return this.tokens;
      },
      filteredUsersGroups: function () {
        const users = [];
        if (this.userInfo && this.userInfo.organization) {
          for (const index in this.userInfo.organization.users) {
            if (this.userInfo.organization.users[index].login === this.$store.state.account.user.username) {
              continue;
            }
            users.push({ name: this.userInfo.organization.users[index].login, action: false });
          }
        }
        return users;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .section-content {
    display: flex;

    .list-container {
      margin-left: 20px;
      margin-right: 20px;
      flex: 1;
    }

    .howTo {
      color: #207BDD;
      font-weight: 200;
      font-size: 16px;
      font-family: Poppins;
      margin-left: 30px;
      font-size: 14px
    }

    .card-title {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      color: #207BDD;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;

    }

    .card-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: 'tnum' on, 'lnum' on;

      color: #6278A3;
      .card-style {
        font-family: Poppins;
        font-weight: 600;
        font-size: 19px;
        line-height: 21px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #6278A3;
      }
    }

    .corporate-button {
      background: #207BDD;
      border-radius: 4px;
      border-color: #207BDD;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0em;
      text-align: center;
      width: 150px;
      margin-left: 290px;
      margin-top: 10px;
      text-transform: none;
      box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
      0 0 0 0px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
      &:hover {
        background-color: #b3c5d4;
        transition: background-color 0.2s linear;
      }
    }

    .item-table {
      border-radius: 4px;
      background-color: #FFFFFF;
      margin-bottom: 3px;
    }

    .item-token-table {
      border-radius: 4px;
      background-color: #FFFFFF;
      cursor: pointer;
    }

    .delete-link {
      /* Remover minha conta */
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin-left: 15px;
      line-height: 140%;
      /* identical to box height, or 20px */
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;

      /* vermelho */
      color: #E94545;
    }
  }
</style>
